<template>
  <div
    class="pb-2 pt-2 pr-6 pl-6 d-flex align-center justify-space-between "
    :style="`background-color: #445D70; column-gap: 38px`"
  >

    <v-skeleton-loader
      :loading="loading"
      type="card-avatar"
      :class="{ 'skeleton-bg' : loading }"
      height="32px"
      class="rounded-lg"
    >
      <h4  :style="`color: ${wsWHITETEXT}`" class="title"> {{ name }}</h4>
    </v-skeleton-loader>

    <div class="d-flex justify-space-between align-center">
      <v-skeleton-loader
        :loading="loading"
        type="card-avatar"
        width="fit-content"
        :class="{ 'skeleton-bg small' : loading }"
        class="rounded-lg mr-6"
        height="32px"
      >
        <div class="d-flex justify-space-between align-center" style="column-gap: 8px">
          <v-btn icon :color="wsWHITETEXT"><v-icon>mdi-account-multiple-outline</v-icon></v-btn>
          <h5 class="wsWHITETEXT">{{ ONLINE_PARTICIPANTS_COUNT }}</h5>
        </div>
      </v-skeleton-loader>

      <v-skeleton-loader
        :loading="loading"
        type="card-avatar"
        width="fit-content"
        :class="{ 'skeleton-bg small' : loading }"
        class="rounded-lg"
        height="32px"
      >
        <ws-button
          icon="mdi-exit-to-app"
          :color="`${wsWARNING}`"
          min-width="56px"
          @click="$emit('close')"
        />
      </v-skeleton-loader>
    </div>


  </div>
</template>

<script>
import webinars from "@/modules/webinars/mixins/webinars"

export default {
  name: "wsWebinarRoomHeader.vue",
  mixins: [webinars],
  props: {
    name: {
      type: String,
      default: ''
    },
  },
  computed: {
    loading() {
      return !this.WEBINAR.uuid
    }
  }
}
</script>



<style scoped>
.title {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
}
</style>
